import Post from "../post/Post";
import datamodeling from "../../images/datamodeling-s-mixeddml.png";
import application from "../../images/application-s-mixeddml.png";
import approvalprocess from "../../images/approvalprocess-s-mixeddml.png";
import datamanagement from "../../images/datamanagement-s-mixeddml.png";
import fields from "../../images/fields-s-mixeddml.png";
import introduction from "../../images/introduction-s-mixeddml.png";
import permissionsets from "../../images/permissionsets-s-mixeddml.png";
import processbuilder from "../../images/processbuilder-s-mixeddml.png";
import queues from "../../images/queues-s-mixeddml.png";
import quickaction from "../../images/quickaction-s-mixeddml.png";
import recordtypes from "../../images/recordtypes-s-mixeddml.png";
import security from "../../images/security-s-mixeddml.png";
import usermanagement from "../../images/usermanagement-s-mixeddml.png";
import workflowrules from "../../images/workflowrules-s-mixeddml.png";
import "./posts.css";

export default function Posts() {
  return (
    <div className="posts">
      <Post
        img={introduction}
        title="Introduction"
        date="28 Nov 2022"
        description="Salesforce - A CLOUD based platform - Used to build Customer Relationship Management solutions. Cloud Computing - On demand provisioning of shared pool of resources - Three Models (based on the solution that is sold to the customer):"
        reflink="/post/admin/introduction"
      />
      <Post
        img={datamodeling}
        title="Data Modeling"
        date="28 Nov 2022"
        description="Terminologies - For easy retrieval and maintenance, Data is stored in structured format called Tables. Tables = Objects | Columns = Fields | Rows = Records. Salesforce Objects - Standard Objects – available out of box from Salesforce - Custom Objects"
        reflink="/post/admin/datamodeling"
      />
      <Post
        img={fields}
        title="Fields"
        date="28 Nov 2022"
        description="Picklist Field - Restricted Vs UnRestricted - Restricted Picklist - accepts only predefined set of values - Error – Bad value for restricted picklist - UnRestricted Picklist - accepts any value. If the value is not in the predefined set, then the value "
        reflink="/post/admin/fields"
      />
      <Post
        img={recordtypes}
        title="Record Types"
        date="28 Nov 2022"
        description="Record Types - allows to have different page layouts and different picklist values for different users based on the user profile - record type of a record is stored in RecordTypeId system field. Page Layout Assignment - mapping to set different "
        reflink="/post/admin/recordtypes"
      />
      <Post
        img={datamanagement}
        title="Data Management"
        date="28 Nov 2022"
        description="Import And Export Tools - Import Tools - Data Import Wizard - Data Loader - Export Tools - Data Export - Data Loader. Data Import Wizard - Out of box tool - Import upto 50000 records at a time - Supported standard objects - accounts, contacts,"
        reflink="/post/admin/datamanagement"
      />
      <Post
        img={usermanagement}
        title="User Management"
        date="28 Nov 2022"
        description="User - create users to give access to an Org - Required Fields : Username (unique across Salesforce), Email Id, Last Name, Alias, License and Profile - Role is an optional field - User Time zone and Language can be set in the Locale settings"
        reflink="/post/admin/usermanagement"
      />
      <Post
        img={security}
        title="Security"
        date="28 Nov 2022"
        description="Security Levels - Org Level - User Activation / Deactivation - Password Policies - Login IP Range - Login Hours - Object Level - CRUD - Field Level - FLS - Record Level - OWD - Role Hierarchy - Sharing Rules - Manual Sharing. Password Policies "
        reflink="/post/admin/security"
      />
      <Post
        img={permissionsets}
        title="Permission Set"
        date="28 Nov 2022"
        description="Profile - is a collection of settings and permissions - Custom profiles can be created by cloning the existing profile. - Once cloned, the exiting permissions can be changed in the cloned profile - assigned to users - A user can be assigned  "
        reflink="/post/admin/permissionset"
      />
      <Post
        img={quickaction}
        title="Quick Action"
        date="28 Nov 2022"
        description="Quick Action - to perform some actions like creating a record / sending an email from a page without having to redirect to a different page. Button Vs Quick Action - buttons when clicked can just open a URL, run JavaScript or open a Visualforce page "
        reflink="/post/admin/quickaction"
      />
      <Post
        img={workflowrules}
        title="Workflow Rule"
        date="28 Nov 2022"
        description="Workflow Rules - declarative automation tool - can be invoked only on record changes. Steps - Choose the object - Set the criteria - Add Immediate / Time dependent actions. Actions - create a task - send email - field update on the same record"
        reflink="/post/admin/workflowrule"
      />
      <Post
        img={processbuilder}
        title="Process Builder"
        date="28 Nov 2022"
        description="Process Builder - declarative automation tool - can be invoked on record changes, platform event message or from other process. Steps - Choose the object - Set the criteria - Add Immediate / Scheduled actions. Actions - Invoke Apex - Create "
        reflink="/post/admin/processbuilder"
      />
      <Post
        img={approvalprocess}
        title="Approval Process"
        date="28 Nov 2022"
        description="Approval Process - declarative automation tool - an approval workflow can be created on a object and follow up actions can be set on Approval or Rejection - entry criteria can be set for records if only subset of records has to enter the "
        reflink="/post/admin/approvalprocess"
      />
      <Post
        img={application}
        title="Application"
        date="28 Nov 2022"
        description="Application - collection of tabs Application Config - create apps from App Manager and do branding - configure navigation style and form factors - add utility items and navigation items - access to application – users with selected profiles can access the app"
        reflink="/post/admin/application"
      />
      <Post
        img={queues}
        title="Queue"
        date="28 Nov 2022"
        description="Queues - Queues can be created to contain group of users who can share the workload more effectively. - A queue is an entity to which records can be routed to await processing by a queue member. - The records remain in the queue until a user"
        reflink="/post/admin/queue"
      />
    </div>
  );
}
