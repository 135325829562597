import Introductionpost from "../../components/Introductionpost";
import { Helmet } from "react-helmet";

export default function Single() {
  return (
    <div style={{ display: "flex" }}>
      <Helmet>
        <title>Introduction to Salesforce | mixedDML.com</title>
      </Helmet>
      <Introductionpost />
    </div>
  );
}
