import RecordTypesPost from "../../components/RecordTypesPost";
import { Helmet } from "react-helmet";

export default function Single() {
  return (
    <div style={{ display: "flex" }}>
      <Helmet>
        <title>Record Types | mixedDML.com</title>
      </Helmet>
      <RecordTypesPost />
    </div>
  );
}
