import WorkflowRulePost from "../../components/WorkflowRulePost";
import { Helmet } from "react-helmet";

export default function Single() {
  return (
    <div style={{ display: "flex" }}>
      <Helmet>
        <title>Workflow Rule | mixedDML.com</title>
      </Helmet>
      <WorkflowRulePost />
    </div>
  );
}
