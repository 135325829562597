import { Link } from "react-router-dom";
import "../styles/post.css";
import { Grid } from "@material-ui/core";
import img from "../images/approvalprocess-l-mixeddml.png";
import { isMobile } from "react-device-detect";

export default function FieldsPost() {
  var a = 3,
    b = 6,
    c = 3;
  if (isMobile) {
    a = 0;
    b = 12;
    c = 0;
  }
  return (
    <Grid container>
      <Grid item xs={a}></Grid>
      <Grid item xs={b}>
        <div className="singlePost">
          <div className="singlePostWrapper">
            <img className="singlePostImg" src={img} alt="" />
            <h1 className="singlePostTitle">Approval Process</h1>
            <div className="singlePostInfo">
              <span>
                Author:
                <b className="singlePostAuthor">
                  <Link className="link" to="/posts?username=Safak">
                    Yogesh
                  </Link>
                </b>
              </span>
              <span>20 Nov 2022</span>
            </div>

            <p className="singlePostDesc">
              Approval Process
              <br />
              - declarative automation tool
              <br />
              - an approval workflow can be created on a object and follow up
              actions can be set on Approval or Rejection
              <br />
              - entry criteria can be set for records if only subset of records
              has to enter the approval process
              <br />
              - separate page layout is set for the approval page <br />
              - approval requests can also be revoked
              <br />
              - approver can either accept / reject / reassign the approval
              request
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Stages
              <br />
              - Initial Submission Action
              <br />
              - Final Approval Action
              <br />
              - Final Rejection Action
              <br />
              - Recall Action
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Action
              <br />
              - Email Alert
              <br />
              - Field Update on the same record
              <br />
              - Outbound Message
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Exercise
              <br />
              Deals__c with value greater than 15 million has to be approved by
              Manager before the deal is getting closed. Other deals which are
              less than 15 million does not need any approval hence can be
              closed directly. There are many managers in the organization hence
              assign the pending approvals to the queue from where managers can
              pick and process the pending approval requests. If the request is
              rejected send an email to the account owner that the approval has
              been rejected. The approval history has to be shown in the record.
              <br />
              <br />
            </p>
          </div>
        </div>
      </Grid>
      <Grid item xs={c}></Grid>
    </Grid>
  );
}
