import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ReactGA from "react-ga4";
ReactGA.initialize("G-YW4QJK9FTE");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
