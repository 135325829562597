import PermissionsetPost from "../../components/PermissionsetPost";
import { Helmet } from "react-helmet";

export default function Single() {
  return (
    <div style={{ display: "flex" }}>
      <Helmet>
        <title>Permission Sets | mixedDML.com</title>
      </Helmet>
      <PermissionsetPost />
    </div>
  );
}
