import { Link } from "react-router-dom";
import "../styles/post.css";
import { Grid } from "@material-ui/core";
import img from "../images/fields-l-mixeddml.png";
import { isMobile } from "react-device-detect";

export default function FieldsPost() {
  var a = 3,
    b = 6,
    c = 3;
  if (isMobile) {
    a = 0;
    b = 12;
    c = 0;
  }
  return (
    <Grid container>
      <Grid item xs={a}></Grid>
      <Grid item xs={b}>
        <div className="singlePost">
          <div className="singlePostWrapper">
            <img className="singlePostImg" src={img} alt="" />
            <h1 className="singlePostTitle">Fields</h1>
            <div className="singlePostInfo">
              <span>
                Author:
                <b className="singlePostAuthor">
                  <Link className="link" to="/posts?username=Safak">
                    Yogesh
                  </Link>
                </b>
              </span>
              <span>17 Nov 2022</span>
            </div>

            <p className="singlePostDesc">
              Picklist Field - Restricted Vs UnRestricted
              <br />
              - Restricted Picklist
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- accepts only predefined set of values
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- Error – Bad value for restricted
              picklist
              <br />
              <br />
              - UnRestricted Picklist
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- accepts any value. If the value is not
              in the predefined set, then the value will be added as an Inactive
              picklist value
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Populating Picklist Values
              <br />
              - Global picklist value Set
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- used to share values across different
              objects and custom picklist fields
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- It is always Restricted
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- can be used for both picklist and
              multiselect picklist
              <br />
              - Field Level
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- can be Restricted / UnRestricted
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Field Dependencies
              <br />
              - Dependent Picklist
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- picklist whose values are dependent on
              some other controlling field.
              <br />
              - Controlling Field
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- field which determines the values of the
              dependent picklist
              <br />
              v- can be a picklist field or checkbox field
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Multi Select Picklist
              <br />
              - allows selecting more than one value
              <br />
              - the multiple values are separated by semi-colon ;<br />
              - the values are always restricted
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Roll Up Summary
              <br />
              - only available if there is Master Detail relationship between
              parent and child object
              <br />
              - used to get summary of the child records at the parent record
              <br />
              - it is Read Only
              <br />
              - Summary : COUNT, SUM, MIN, MAX
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Formula field
              <br />
              - used to perform computations
              <br />
              - it is Read Only
              <br />
              <br />
            </p>
            <p className="singlePostDesc">
              Cross Reference Formula
              <br />
              - formula that references parent object’s fields
              <br />
              - __r.parentfield
              <br />
              - Owner.name
              <br />
              - Account.name
              <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </Grid>
      <Grid item xs={c}></Grid>
    </Grid>
  );
}
