import ApprovalProcessPost from "../../components/ApprovalProcessPost";
import { Helmet } from "react-helmet";

export default function Single() {
  return (
    <div style={{ display: "flex" }}>
      <Helmet>
        <title>Approval Process | mixedDML.com</title>
      </Helmet>
      <ApprovalProcessPost />
    </div>
  );
}
