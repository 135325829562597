import { Link } from "react-router-dom";
import "../styles/post.css";
import insToOrg from "../images/InstanceToOrg.png";
import poolToIns from "../images/SharedPoolToInstance.png";
import { Grid } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import img from "../images/introduction-l-mixeddml.png";

export default function Multitenancypost() {
  var a = 3,
    b = 6,
    c = 3;
  if (isMobile) {
    a = 0;
    b = 12;
    c = 0;
  }
  return (
    <Grid container>
      <Grid item xs={a}></Grid>
      <Grid item xs={b}>
        <div className="singlePost">
          <div className="singlePostWrapper">
            <img className="singlePostImg" src={img} alt="" />
            <h1 className="singlePostTitle">Mutitenancy In Salesforce</h1>
            <div className="singlePostInfo">
              <span>
                Author:
                <b className="singlePostAuthor">
                  <Link className="link" to="/posts?username=Safak">
                    Yogesh
                  </Link>
                </b>
              </span>
              <span>20 Nov 2022</span>
            </div>
            <p className="singlePostDesc">
              Salesforce run in a multitenancy environment. One difference that
              makes the Salesforce developer different from other development is
              that the consicousness of the multitenant environment.
              <br />
              <br />
              What is Mutitenancy ? As the name implies Multi + Tenant - some
              space is shared by mutiple occupants. In Salesforce, an Instance
              is shared by mutliple Org. Haa! Too many Jargons! Lets go step by
              step. Before getting into Multitenancy, lets understand cloud
              computing. Cloud compution is noting but on demand provisioning of
              shared pool of resources. Lets consider a training institute which
              currently has 5 studends - Jan, Feb, Mar, Apr and May Each student
              has laptop with 8 cores CPU and 1TB storage and 4 GB RAM. None of
              the students fully utilize the availble resources. All of them
              make use of 4 core CPU, 200MB storage and 2 GB RAM maximum. So the
              excess resources are in reserved in each students laptop and is
              under utilized Jan analysed the situation and started thinking of
              renting out these resources to earn from them. But the available
              resource in each laptop is less and hence cannot be sold
              separartely and hence Jan thought if he can combine all the
              availble resources and
              <br />
              <img className="singlePostImg1" src={poolToIns} alt="" />
              <br />
              <br />
              <img className="singlePostImg1" src={insToOrg} alt="" />
            </p>
          </div>
        </div>
      </Grid>
      <Grid item xs={c}></Grid>
    </Grid>
  );
}
