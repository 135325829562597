import { Link } from "react-router-dom";
import "../styles/post.css";
import { Grid } from "@material-ui/core";
import img from "../images/processbuilder-l-mixeddml.png";
import { isMobile } from "react-device-detect";

export default function FieldsPost() {
  var a = 3,
    b = 6,
    c = 3;
  if (isMobile) {
    a = 0;
    b = 12;
    c = 0;
  }
  return (
    <Grid container>
      <Grid item xs={a}></Grid>
      <Grid item xs={b}>
        <div className="singlePost">
          <div className="singlePostWrapper">
            <img className="singlePostImg" src={img} alt="" />
            <h1 className="singlePostTitle">Process Builder</h1>
            <div className="singlePostInfo">
              <span>
                Author:
                <b className="singlePostAuthor">
                  <Link className="link" to="/posts?username=Safak">
                    Yogesh
                  </Link>
                </b>
              </span>
              <span>20 Nov 2022</span>
            </div>

            <p className="singlePostDesc">
              Process Builder
              <br />
              - declarative automation tool
              <br />
              - can be invoked on record changes, platform event message or from
              other process
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Steps
              <br />
              - Choose the object
              <br />
              - Set the criteria
              <br />
              - Add Immediate / Scheduled actions
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Actions
              <br />
              - Invoke Apex
              <br />
              - Create a record
              <br />
              - Email alerts
              <br />
              - Flows <br />
              - Post to Chatter
              <br />
              - Invoke Process
              <br />
              - Quick Action
              <br />
              - Quip
              <br />
              - Send Custom Notification
              <br />
              - Submit for approval
              <br />
              - Update the current record or related record
              <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </Grid>
      <Grid item xs={c}></Grid>
    </Grid>
  );
}
