import { Link } from "react-router-dom";
import "../styles/post.css";
import { Grid } from "@material-ui/core";
import img from "../images/datamanagement-l-mixeddml.png";
import { isMobile } from "react-device-detect";

export default function FieldsPost() {
  var a = 3,
    b = 6,
    c = 3;
  if (isMobile) {
    a = 0;
    b = 12;
    c = 0;
  }
  return (
    <Grid container>
      <Grid item xs={a}></Grid>
      <Grid item xs={b}>
        <div className="singlePost">
          <div className="singlePostWrapper">
            <img className="singlePostImg" src={img} alt="" />
            <h1 className="singlePostTitle">Data Management</h1>
            <div className="singlePostInfo">
              <span>
                Author:
                <b className="singlePostAuthor">
                  <Link className="link" to="/posts?username=Safak">
                    Yogesh
                  </Link>
                </b>
              </span>
              <span>20 Nov 2022</span>
            </div>

            <p className="singlePostDesc">
              Import And Export Tools
              <br />
              - Import Tools
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp; - Data Import Wizard
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp; - Data Loader
              <br />
              - Export Tools
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp; - Data Export
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp; - Data Loader
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Data Import Wizard
              <br />
              - Out of box tool
              <br />
              - Import upto 50000 records at a time
              <br />
              - Supported standard objects - accounts, contacts, leads,
              solutions, campaign members
              <br />
              - Supports all custom objects
              <br />
              - Supports Insert, Update and Upsert
              <br />
              - Workflow and processes can be disabled
              <br />
              - Note : Apex Triggers always fires
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Data Loader Installation steps
              <br />
              - Download DataLoader -
              https://developer.salesforce.com/tools/data-loader
              <br />
              - Download Julu JRE - https://www.azul.com/downloads/?package=jre
              <br />
              - Move the JRE folder to the required location (No Installation
              required)
              <br />
              - Add the path of JRE bin to System Environment Variables
              <br />
              - Install Data Loader
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Data Loader Import
              <br />
              - Load upto 5 Million records
              <br />
              - Supports all standard and custom objects
              <br />
              - Supports Insert, Update, Upsert and Delete
              <br />
              - Supports Import/export only from/to CSV
              <br />
              - Supports CLI operations and scheduled jobs
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Data Export
              <br />
              - out of box tool
              <br />
              - to export a copy of all the records from one/multiple/all
              objects all at once
              <br />
              - all fields in the object are included
              <br />
              - records cannot be filtered
              <br />
              - export can be scheduled
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Data Loader Export
              <br />
              - export one object at a time
              <br />
              - fields can be cherry picked
              <br />
              - records can be filtered with conditions
              <br />
              Export Vs Export All
              <br />
              - Export does not include soft deleted records
              <br />
              - Export All includes soft deleted records(i.e., records in
              Recycle Bin)
              <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </Grid>
      <Grid item xs={c}></Grid>
    </Grid>
  );
}
