import { Link } from "react-router-dom";
import "./topbar.css";

export default function Topbar() {
  return (
    <div className="top">
      <div className="topLeft">
        <Link
          className="link"
          to={{ pathname: "https://www.linkedin.com/in/yogeshthannasi/" }}
          target="_blank"
        >
          <i className="topIcon fab fa-linkedin"></i>
        </Link>
        {/*
        <i className="topIcon fab fa-instagram-square"></i>
        <i className="topIcon fab fa-pinterest-square"></i>
        <i className="topIcon fab fa-twitter-square"></i>
      */}
      </div>
      <div className="topCenter">
        <ul className="topList">
          <li className="topListItem">
            <Link className="link" to="/">
              HOME
            </Link>
          </li>
          <li className="topListItem">
            <Link
              className="link"
              to={{ pathname: "https://www.linkedin.com/in/yogeshthannasi/" }}
              target="_blank"
            >
              ABOUT
            </Link>
          </li>
          {/*
          <li className="topListItem">CONTACT</li>
          */}
        </ul>
      </div>
      <div className="topRight">
        <i className="topSearchIcon fas fa-search"></i>
      </div>
    </div>
  );
}
