import Multitenancypost from "../../components/Multitenancypost";
import { Helmet } from "react-helmet";

export default function Multitenancy() {
  return (
    <div style={{ display: "flex" }}>
      <Helmet>
        <title>Multitenancy | mixedDML.com</title>
      </Helmet>
      <Multitenancypost />
    </div>
  );
}
