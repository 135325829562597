import { useLocation } from "react-router";
import Header from "../../components/header/Header";
import Posts from "../../components/posts/Posts";
import "./homepage.css";
import { Helmet } from "react-helmet";

export default function Homepage() {
  const location = useLocation();
  console.log(location);
  return (
    <>
      <Helmet>
        <title>Home | mixedDML.com</title>
      </Helmet>
      <hr size="8" width="100%" color="#1798c1"></hr>
      <Header />
      <hr size="8" width="100%" color="Gold"></hr>
      <br />
      <br />
      <div className="home">
        <Posts />
      </div>
    </>
  );
}
