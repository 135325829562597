import Topbar from "./components/topbar/Topbar";
import Homepage from "./pages/homepage/Homepage";
import Multitenancy from "./pages/multitenancy/Multitenancy";
import Introduction from "./pages/introduction/Introduction";
import DataModeling from "./pages/datamodeling/DataModeling";
import RecordTypes from "./pages/recordtypes/RecordTypes";
import DataManagement from "./pages/datamanagement/DataManagement";
import UserManagement from "./pages/usermanagement/UserManagement";
import Permissionset from "./pages/permissionset/Permissionset";
import QuickAction from "./pages/quickaction/QuickAction";
import WorkflowRule from "./pages/workflowrule/WorkflowRule";
import Security from "./pages/security/Security";
import ProcessBuilder from "./pages/processbuilder/ProcessBuilder";
import ApprovalProcess from "./pages/approvalprocess/ApprovalProcess";
import Fields from "./pages/fields/Fields";
import Application from "./pages/application/Application";
import Queue from "./pages/queue/Queue";
import Testyourknowledge from "./pages/testyourknowledge/Testyourknowledge";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Topbar />
      <Switch>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route path="/posts">
          <Homepage />
        </Route>
        <Route path="/post/dev/multitenancy">
          <Multitenancy />
        </Route>
        <Route path="/post/admin/introduction">
          <Introduction />
        </Route>
        <Route path="/post/admin/datamodeling">
          <DataModeling />
        </Route>
        <Route path="/post/admin/fields">
          <Fields />
        </Route>
        <Route path="/post/admin/recordtypes">
          <RecordTypes />
        </Route>
        <Route path="/post/admin/datamanagement">
          <DataManagement />
        </Route>
        <Route path="/post/admin/usermanagement">
          <UserManagement />
        </Route>
        <Route path="/post/admin/security">
          <Security />
        </Route>
        <Route path="/post/admin/permissionset">
          <Permissionset />
        </Route>
        <Route path="/post/admin/quickaction">
          <QuickAction />
        </Route>
        <Route path="/post/admin/workflowrule">
          <WorkflowRule />
        </Route>
        <Route path="/post/admin/processbuilder">
          <ProcessBuilder />
        </Route>
        <Route path="/post/admin/approvalprocess">
          <ApprovalProcess />
        </Route>
        <Route path="/post/admin/application">
          <Application />
        </Route>
        <Route path="/post/admin/queue">
          <Queue />
        </Route>
        <Route path="/post/dev/testyourknowledge">
          <Testyourknowledge />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
