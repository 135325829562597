import { Link } from "react-router-dom";
import "../styles/post.css";
import { Grid } from "@material-ui/core";
import img from "../images/permissionset-l-mixeddml.png";
import { isMobile } from "react-device-detect";

export default function FieldsPost() {
  var a = 3,
    b = 6,
    c = 3;
  if (isMobile) {
    a = 0;
    b = 12;
    c = 0;
  }
  return (
    <Grid container>
      <Grid item xs={a}></Grid>
      <Grid item xs={b}>
        <div className="singlePost">
          <div className="singlePostWrapper">
            <img className="singlePostImg" src={img} alt="" />
            <h1 className="singlePostTitle">
              Profiles, Permission Sets And Permission Set Groups
            </h1>
            <div className="singlePostInfo">
              <span>
                Author:
                <b className="singlePostAuthor">
                  <Link className="link" to="/posts?username=Safak">
                    Yogesh
                  </Link>
                </b>
              </span>
              <span>20 Nov 2022</span>
            </div>

            <p className="singlePostDesc">
              Profile
              <br />
              - is a collection of settings and permissions
              <br />
              - Custom profiles can be created by cloning the existing profile.
              <br />
              - Once cloned, the exiting permissions can be changed in the
              cloned profile <br />
              - assigned to users
              <br />
              - A user can be assigned to only one profile
              <br />
              - One profile can be assigned to multiple users
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Permission set
              <br />
              - is a collection of settings and permissions
              <br />
              - permission sets extends the profile permissions without having
              to change profile
              <br />
              - multiple permission set can be assigned to one user
              <br />
              - One permission set can be assigned to multiple user <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Permission set group
              <br />
              - bundle of permission sets
              <br />
              - includes all the permissions from different permission sets
              <br />
              <br />
              Muting Permissions
              <br />
              - to remove the permission from the permission set group rather
              have to remove the permission from permission set
              <br />
              - the muted permission will remain in the permission set but is
              only removed from the permission set group
              <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </Grid>
      <Grid item xs={c}></Grid>
    </Grid>
  );
}
