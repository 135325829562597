import { Link } from "react-router-dom";
import "../styles/post.css";
import { Grid } from "@material-ui/core";
import img from "../images/usermanagement-l-mixeddml.png";
import { isMobile } from "react-device-detect";

export default function FieldsPost() {
  var a = 3,
    b = 6,
    c = 3;
  if (isMobile) {
    a = 0;
    b = 12;
    c = 0;
  }
  return (
    <Grid container>
      <Grid item xs={a}></Grid>
      <Grid item xs={b}>
        <div className="singlePost">
          <div className="singlePostWrapper">
            <img className="singlePostImg" src={img} alt="" />
            <h1 className="singlePostTitle">User Management</h1>
            <div className="singlePostInfo">
              <span>
                Author:
                <b className="singlePostAuthor">
                  <Link className="link" to="/posts?username=Safak">
                    Yogesh
                  </Link>
                </b>
              </span>
              <span>20 Nov 2022</span>
            </div>

            <p className="singlePostDesc">
              User
              <br />
              - create users to give access to an Org
              <br />
              - Required Fields : Username (unique across Salesforce), Email Id,
              Last Name, Alias, License and Profile <br />
              - Role is an optional field
              <br />
              - User Time zone and Language can be set in the Locale settings
              <br />
              - Reset User Password
              <br />
              - Sharing for the user record can be configured
              <br />
              - Freeze / Deactivate an account
              <br />
              - User record once created cannot be deleted
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Freeze Vs Deactivate
              <br />
              - Freeze - just prevents user from Logging in
              <br />
              - Deactivate – deactivates the user and frees up the User License
              <br />
              - When a user record is set as a value for some custom
              hierarchical field in any of the User record, then the user record
              cannot be deactivated hence it can be only freezed
              <br />
              *Yet To Be Verified*
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Standard Profiles
              <br />
              - System Administrator – Access to all configuration and
              functionality
              <br />
              - Standard User – Read/Edit access to records but only Read access
              to configuration
              <br />
              - Read Only – Read only access to records and Read only access to
              config <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </Grid>
      <Grid item xs={c}></Grid>
    </Grid>
  );
}
