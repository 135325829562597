import { Link } from "react-router-dom";
import "../styles/post.css";
import { Grid } from "@material-ui/core";
import img from "../images/datamodeling-l-mixeddml.png";
import { isMobile } from "react-device-detect";

export default function FieldsPost() {
  var a = 3,
    b = 6,
    c = 3;
  if (isMobile) {
    a = 0;
    b = 12;
    c = 0;
  }
  return (
    <Grid container>
      <Grid item xs={a}></Grid>
      <Grid item xs={b}>
        <div className="singlePost">
          <div className="singlePostWrapper">
            <img className="singlePostImg" src={img} alt="" />
            <h1 className="singlePostTitle">Data Modeling</h1>
            <div className="singlePostInfo">
              <span>
                Author:
                <b className="singlePostAuthor">
                  <Link className="link" to="/posts?username=Safak">
                    Yogesh
                  </Link>
                </b>
              </span>
              <span>17 Nov 2022</span>
            </div>

            <p className="singlePostDesc">
              Terminologies
              <br />
              - For easy retrieval and maintenance, Data is stored in structured
              format called Tables
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;Tables = Objects
              <br /> &nbsp;&nbsp;&nbsp;&nbsp;Columns = Fields <br />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;Rows = Records
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Salesforce Objects
              <br />
              - Standard Objects – available out of box from Salesforce
              <br />
              - Custom Objects – additional objects created by admin/developer
              (end with __c suffix)
              <br />
              - Sales Cloud - Leads, Opportunities, Campaigns <br />
              - Service Cloud - Cases, Entitlements, Tasks, Events
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Fields
              <br />
              - Standard Fields – Available out of box on all Salesforce objects
              <br />
              - ID - 15-character, case-sensitive field, unique for every
              Salesforce record <br />
              - Name - A name to identify the records
              <br />
              - System – CreatedDate, LastModifedDate, LastModifiedBy
              <br />
              <br />
              - Custom Fields – Additional fields which the admin/dev created on
              standard & custom objects
              <br />
              - Custom field’s API name ends with __c suffix
              <br />
              - Owner
              <br />
              Note : First three digits of the record Id denotes the object to
              which the record belongs to. For Example all Account records
              starts with '001'
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Relationship
              <br />
              - To avoid data duplication, data is stored in multiple tables and
              the related records are linked by reference keys.
              <br />
              - In Salesforce, the records are related to each other through
              Relationship fields
              <br />
              - In a parent child relationship, the Relationship field will
              always be at the Child object.
              <br />
              - This relationship field in the child record stores the record Id
              of the parent record to which the child record is related to.
              <br />
              - __c
              <br />
              - __r
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Types of Relationships
              <br />
              - Primary Types
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;1. Lookup <br />
              &nbsp;&nbsp;&nbsp;&nbsp;2. Master Detail
              <br />
              <br />
              - Other Types:
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;1. External
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;2. Indirect
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;3. Self
              <br />
              <br />
              <br />
              <br />
            </p>
            <p className="singlePostDesc">
              External Object
              <br />
              <br />
              - External objects are used to access data that is stored outside
              the Salesforce organization
              <br />
              - Each External Object is connected with an External data source
              <br />
              - External data source defines the method to get access into the
              External system
              <br />
              - Salesforce Connect uses External Data Source to get access into
              external data source
              <br />
              - External Object is just the schema defined in the Salesforce
              system which maps to the actual schema in the external data source
              so that the external data can be accessed using the External
              object as a reference
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Recycle Bin
              <br />
              - Records deleted in Salesforce will be retained in the recycle
              bin for a period of 15 days <br />
              - Records can be restored if required during this retention period
              <br />
              - After 15 days, the deleted records will be purged and hence
              cannot be recovered any more
              <br />
              - IsDeleted - the field that will be marked TRUE for the soft
              deleted records
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Master Detail
              <br />
              - Tightly coupled
              <br />
              - Child records can be created only when linked to parent records
              <br />
              - When the parent record is deleted, the child records get
              recursively deleted
              <br />
              - The Ownership and sharing of the child record is determined by
              the parent record
              <br />
              - Rollup summary field can be created on the Parent object when it
              is linked to Child object through a Master Detail Relationship
              <br />
              - Related Lists
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              LookUp Relationship
              <br />
              - Loosely bound
              <br />
              - Child records can be created without linking the child record to
              the parent
              <br />
              - When the parent record is deleted, the child records remain
              undeleted and the Id of the parent record in the relationship
              field is removed
              <br />
              - The Ownership and sharing are independent for the parent and
              child objects
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              LookUp to MasterDetail - Conversion
              <br />
              - If there exists child records which are not related to any
              parent record, the the child records have to be linked / cleaned
              to perform this conversion. <br />
              - You cannot create a new Master-Detail relationship on an
              existing custom object if records already exist. You must first
              create a Lookup relationship, populate the lookup field with data
              in all records, and then change the relationship type to
              Master-Detail.
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Master Detail to LookUp - Conversion
              <br />
              - This relationship change is always possible and does not cause
              any issue with the existing data
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Reparenting
              <br />
              - Master detail relationship does not allow reparenting of
              recorods by default.
              <br />
              - But can be configured in the object to allow reparenting
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Junction Object
              <br />
              - Many to Many relationship between two objects can be established
              in Salesforce by creating a Intermediate object which has Master
              Details relationship to both objects
              <br />
              - The Intermediate object which has the Master Detail relationship
              to the other two objects is called Junction object
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Limits
              <br />
              - Max No. of Master Detail Relationships per Object – 2
              <br />
              - Max No. of Relationships per Object – 40
              <br />
              - MD & Lookup
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;2 & 38
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;1 & 39
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;0 & 40
              <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </Grid>
      <Grid item xs={c}></Grid>
    </Grid>
  );
}
