import { Link } from "react-router-dom";
import "../styles/post.css";
import { Grid } from "@material-ui/core";
import img from "../images/security-l-mixeddml.png";
import { isMobile } from "react-device-detect";

export default function FieldsPost() {
  var a = 3,
    b = 6,
    c = 3;
  if (isMobile) {
    a = 0;
    b = 12;
    c = 0;
  }
  return (
    <Grid container>
      <Grid item xs={a}></Grid>
      <Grid item xs={b}>
        <div className="singlePost">
          <div className="singlePostWrapper">
            <img className="singlePostImg" src={img} alt="" />
            <h1 className="singlePostTitle">Security</h1>
            <div className="singlePostInfo">
              <span>
                Author:
                <b className="singlePostAuthor">
                  <Link className="link" to="/posts?username=Safak">
                    Yogesh
                  </Link>
                </b>
              </span>
              <span>20 Nov 2022</span>
            </div>

            <p className="singlePostDesc">
              Security Levels
              <br />
              - Org Level <br />
              - User Activation / Deactivation
              <br />
              - Password Policies
              <br />
              - Login IP Range
              <br />
              - Login Hours
              <br />
              <br />
              - Object Level
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- CRUD
              <br />
              <br />
              - Field Level <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- FLS
              <br />
              <br />
              - Record Level
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- OWD
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- Role Hierarchy
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- Sharing Rules
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;- Manual Sharing
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Password Policies
              <br />
              - to set the password restrictions like the password expiry,
              minimum password length, etc and login lockout policy
              <br />
              - applies to all users in the Org
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Login IP Range
              <br />
              - set at profile level
              <br />
              - define the valid IP range from which the users with the
              particular profile can login
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Login Hours
              <br />
              <br />
              - set at profile level
              <br />
              - define the login time range when the users with that particular
              profile are allowed to log in into the org can
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Object CRUD permission
              <br />
              - define the object level permission for the users with profile &
              permission set
              <br />
              - Read
              <br />
              - Create
              <br />
              - Edit
              <br />
              - Delete
              <br />
              - View All – ignores record sharing rules and settings
              <br />
              - Modify All – ignores record sharing rules and settings
              <br />
              - Modify All does not grant create permission on the object
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              System Admin
              <br />
              - User with the System Admin profile can see and modify all
              records in all objects in the Org.
              <br />
              - Two Administrative permissions – View All data & Modify All data
              <br />
              - If any other user has to be given Read / Modify permission to
              all records in one/multiple objects (not all objects), then
              setting View All and Modify All at each object level can be better
              compared to View All data & Modify All data
              <br />
              <br /> <br />
            </p>

            <p className="singlePostDesc">
              Field Level Security
              <br />
              - define the field level permission for the users with profile &
              permission set
              <br />
              - Read Access
              <br />
              - Edit Access
              <br />
              <br />
              - A field can be set as Read Only in the page layout also but it
              does not prevent editing the field from custom component / from
              the backend operations.
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Record Level Security
              <br />
              - Visibility to records opens up in the order – OWD ===&gt; Role
              Hierarchy ===&gt; Sharing Rules ===&gt; Manual Sharing
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;1. OWD – defines the base level of access,
              should be more restrictive hence can be opened up in higher levels
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;2. Role Hierarchy – sharing based on user
              role and the role’s position in hierarchy
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;3. Sharing Rules – criteria based and
              owner ship based sharing
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;4. Manual sharing – sharing a record from
              record detail <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Public Group
              <br />
              - collection of individual users / roles / territories / groups
              <br />
              - records can be shared to public groups
              <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </Grid>
      <Grid item xs={c}></Grid>
    </Grid>
  );
}
