import ApplicationPost from "../../components/ApplicationPost";
import { Helmet } from "react-helmet";

export default function Single() {
  return (
    <div style={{ display: "flex" }}>
      <Helmet>
        <title>Application | mixedDML.com</title>
      </Helmet>
      <ApplicationPost />
    </div>
  );
}
