import { Link } from "react-router-dom";
import "../styles/post.css";
import { Grid } from "@material-ui/core";
import img from "../images/security-l-mixeddml.png";
import { isMobile } from "react-device-detect";

export default function KnowledgeTestPost() {
  var a = 3,
    b = 6,
    c = 3;
  if (isMobile) {
    a = 0;
    b = 12;
    c = 0;
  }
  return (
    <Grid container>
      <Grid item xs={a}></Grid>
      <Grid item xs={b}>
        <div className="singlePost">
          <div className="singlePostWrapper">
            <img className="singlePostImg" src={img} alt="" />
            <h1 className="singlePostTitle">Test Your Knowledge</h1>
            <div className="singlePostInfo">
              <span>
                Author:
                <b className="singlePostAuthor">
                  <Link className="link" to="/posts?username=Safak">
                    Yogesh
                  </Link>
                </b>
              </span>
              <span>30 Jan 2023</span>
            </div>

            <p className="singlePostDesc">
              Introduction
              <br />
              1. What is Multitenancy?<br />
              2. What are governor limits and why are they required?<br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Data Modeling
              <br />
                1. What are different types of relationships?<br />
                2. Explain and identify the Loosely Bound and Strongly Bound Relationship.<br />
                3. Explain the ownership and sharing in different types of relationships.<br />
                4. What is cascade delete?<br />
                5. What is the type of relationship between Account and Contact ?<br />
                6. What is a relationhip name (Eg: Contacts)? Why is it required?<br />
                7. What is the maximum number of relationhip fields allowed per object? <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Fundamentals
              <br />
                1. What is the field type that cannot be added to an object in Schema Builder? <br />
                2. What is the maximum number of formula fields allowed per object?<br />
                3. When are the Validation Rules executed ?
                4. What are Record Types and why are they required?
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Lightning Flow
              <br />
              1. Design a Flow to Simulate Rollup Summary Field in Lookup Relationship.<br />
              2. Design a Flow that guides a Custome Support user to capture the Caller Details.<br />
              3. Design a Flow that send the updates on customer cases every week.<br />
              4. Design a Flow that can be invoked from Process.<br />
              5. Design a Flow that creates a Log record whenever a LWC component refresh happens.<br />
              <br />
              <br />
            </p>


            <p className="singlePostDesc">
              SOQL
              <br />
              1. Write a query to get Account records which are related to more than 2 contacts.<br />
              2. Write a query to get the total number of open cases in different Billing City.<br />
              3. Write a query to return all users who are assigned more than 3 open cases.<br />  
              4. What is the maximum number of SOQL queries allowed per transaction? <br /> 
              <br />
              <br />
            </p>


            <p className="singlePostDesc">
              DML
              <br />
              1. What is DML and what are the different operations?<br />
              2. What is the advantage of using Database class over native DML Statements?<br />
              3. What is the maximum number of DML allowed per transaction? <br /> 
              <br />
              <br />
            </p>


            <p className="singlePostDesc">
              OOPS
              <br />
              1. What are Classes and Objects?<br />
              2. Explain Inheritance with Example.<br />
              3. What are the different access specifiers at Class level, Method level and Variable level?<br />
              4. What is encapsulation and how is it achieved?<br />
              5. What is a static method?<br />
              6. What is a static variable?<br />
              7. Explain Interface with Example.<br />
              8. What is an abstract class?<br />
              <br />
              <br />
            </p>


            <p className="singlePostDesc">
              Triggers
              <br />
              1. What are the different database events that can be listed to in a trigger?<br /> 
              2. What are context variables and mention the values during different operations?<br /> 
              3. What is Bulkification?<br /> 
              4. Implement Rollup Summary behavior in a Lookup relationship with Trigger.<br />
              <br />
              <br />
            </p>



            <p className="singlePostDesc">
              Asynchronous Apex
              <br />
              1. What is Asynchronous Apex and Why it is required?<br />
              2. What are the different Asynchronous Apex Methods?<br />
              3. How to do Chaining of Jobs?<br />
              4. What is the default batch size in a batch job?<br />
              5. What is CRON expression and what is it used for?<br />
              6. Can we pass Non primitive types as parameters to future method?<br />
              <br />
              <br />
            </p>


            <p className="singlePostDesc">
              Unit Test
              <br />
              1. What is the Code coverage percentage requierd in Salesforce?<br />
              2. How to run unit tests?<br />
              3. How do you create test data for unit tests?<br />
              4. What is Test.startTest and Test.stopTest used for?<br />
              5. What is seeAllData=true used for?<br />
              <br />
              <br />
            </p>


            <p className="singlePostDesc">
              LWC
              <br />
              1. How is LWC better than Aura?<br />
              2. What is Component Rendering Life Cycle and what are the hooks availble?<br />
              3. What is Variable Binding?<br />
              4. What is an event and how to fire and handle an event. What are bubble and capture?<br />
              5. What is Lightning Message Service?<br />
              6. What are the Apex side requirements for invoking an Apex method from LWC?<br />
              7. What are the different modes of Apex invocation?<br />
              8. What does Cacheable=true do and why is it required?<br />
              9. What is LWC composition and how to the coponents in the hierarchy communicate from bottom to top and top to bottom?<br />
              <br />
              <br />
            </p>

          </div>
        </div>
      </Grid>
      <Grid item xs={c}></Grid>
    </Grid>
  );
}
