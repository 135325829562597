import { Link } from "react-router-dom";
import "../styles/post.css";
import { Grid } from "@material-ui/core";
import img from "../images/quickaction-l-mixeddml.png";
import { isMobile } from "react-device-detect";

export default function FieldsPost() {
  var a = 3,
    b = 6,
    c = 3;
  if (isMobile) {
    a = 0;
    b = 12;
    c = 0;
  }
  return (
    <Grid container>
      <Grid item xs={a}></Grid>
      <Grid item xs={b}>
        <div className="singlePost">
          <div className="singlePostWrapper">
            <img className="singlePostImg" src={img} alt="" />
            <h1 className="singlePostTitle">Quick Actions</h1>
            <div className="singlePostInfo">
              <span>
                Author:
                <b className="singlePostAuthor">
                  <Link className="link" to="/posts?username=Safak">
                    Yogesh
                  </Link>
                </b>
              </span>
              <span>20 Nov 2022</span>
            </div>

            <p className="singlePostDesc">
              Quick Action
              <br />
              - to perform some actions like creating a record / sending an
              email from a page without having to redirect to a different page.
              <br />
              <br /> <br />
            </p>

            <p className="singlePostDesc">
              Button Vs Quick Action
              <br />
              - buttons when clicked can just open a URL, run JavaScript or open
              a Visualforce page
              <br />
              - quick actions are not just buttons but configured to perform a
              specific action
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Object specific quick actions
              <br />
              - defined at each object level
              <br />
              - can perform different operations related to object
              <br />
              - can be included in the object page layout
              <br />
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Global quick Actions
              <br />
              - not specific to any object
              <br />
              - to make it available, has to be included in separate layout
              called global publisher layout
              <br />
              - can be included in any page – home page, record page, etc.
              <br />
              - can be accessed from ‘+’ Icon in Lightning
              <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </Grid>
      <Grid item xs={c}></Grid>
    </Grid>
  );
}
