import { Link } from "react-router-dom";
import "./post.css";

export default function Post({ img, title, date, description, reflink }) {
  return (
    <div className="post">
      <Link to={reflink} className="link">
        <img className="postImg" src={img} alt="" />
      </Link>
      <div className="postInfo">
        <span className="postTitle">
          <Link to={reflink} className="link">
            {title}
          </Link>
        </span>
        <hr />
        <span className="postDate">
          <Link to={reflink} className="link">
            {date}
          </Link>
        </span>
      </div>
      <p className="postDesc">
        <Link to={reflink} className="link">
          {description}
        </Link>
      </p>
    </div>
  );
}
