import { Link } from "react-router-dom";
import "../styles/post.css";
import { Grid } from "@material-ui/core";
import img from "../images/introduction-l-mixeddml.png";
import { isMobile } from "react-device-detect";

export default function Introductionpost() {
  var a = 3,
    b = 6,
    c = 3;
  if (isMobile) {
    a = 0;
    b = 12;
    c = 0;
  }
  return (
    <Grid container>
      <Grid item xs={a}></Grid>
      <Grid item xs={b}>
        <div className="singlePost">
          <div className="singlePostWrapper">
            <img className="singlePostImg" src={img} alt="" />
            <h1 className="singlePostTitle">Introduction to Salesforce</h1>
            <div className="singlePostInfo">
              <span>
                Author:
                <b className="singlePostAuthor">
                  <Link className="link" to="/posts?username=Safak">
                    Yogesh
                  </Link>
                </b>
              </span>
              <span>20 Nov 2022</span>
            </div>

            <p className="singlePostDesc">
              Salesforce
              <br />
              - A CLOUD based platform
              <br />
              - Used to build Customer Relationship Management solutions
              <br />
              <br />
            </p>

            <p className="singlePostDesc">
              Cloud Computing
              <br />
              - On demand provisioning of shared pool of resources
              <br />
              - Three Models (based on the solution that is sold to the
              customer):
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;1. Infrastructure As A Service – AWS,
              Azure
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;2. Platform As A Service - Salesforce
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;3. Product As A Service – Sales Cloud,
              Service Cloud, Gmail, GDrive
              <br />
              <br />
            </p>
            <p className="singlePostDesc">
              Multitenancy
              <br />
              - The shared pool of resources is split into instances.
              <br />
              - Each instance can run multiple Orgs.
              <br />
              - An Instance is a combination of physical resources ( CPU cores,
              RAM, Storage )
              <br />
              - The Orgs running in a single Instance share the physical
              resources of that particular instance
              <br />
              - One instance being shared by multiple Orgs is called
              Multitenancy
              <br />
              <br />
            </p>
            <p className="singlePostDesc">
              Salesforce Constraints
              <br />
              - When the resources are shared, there are chances of over
              utilization of resources by some Orgs running in the same instance
              <br />
              - To avoid overutilization, salesforce has set up some
              constraints.
              <br />
              - There constraints are called Governor Limits
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;1. Total heap size/Transaction
              (Synchronous) = 6 MB
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;2. Total number of SOQL queries
              issued/Transaction (Synchronous) = 100
              <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </Grid>
      <Grid item xs={c}></Grid>
    </Grid>
  );
}
