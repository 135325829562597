import DataManagementPost from "../../components/DataManagementPost";
import { Helmet } from "react-helmet";

export default function Single() {
  return (
    <div style={{ display: "flex" }}>
      <Helmet>
        <title>Data Management | mixedDML.com</title>
      </Helmet>
      <DataManagementPost />
    </div>
  );
}
